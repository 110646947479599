import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import constants from '../../utils/constants';
import Divider from '../common/divider';

export default () => {
    const data = useStaticQuery(query);

    const imageSrc = data.image.childImageSharp.fluid.src;

    return (
        <>
            <section id="dostavki-address">

                <Container className="py-5 mt-5 text-center">
                    <div className="pb-5">
                        <h1>За нас</h1>
                        <Divider />
                        <p>Дългогодишни традиции и качество.</p>
                    </div>

                    <Row className="my-5">
                        <Col lg={6} className="text-center text-lg-left">
                            <h3>Нашата история</h3>
                            <Divider />
                            <Image src={imageSrc} fluid alt="Нашата история" className="d-lg-none my-4" />
                            <p>
                                Ние от бистро "Арно" сме в ресторантьорския бизнес вече повече от 20 години, Доказали сме се във времето и знаем, че най-важното за нашите клиенти е храната да е с неповторим вкус и на добра цена. Обслужваме клиентите си с качество и традиции, носени от поколение на поколение.
                                <br /><br />При нас може да намерите всичко това, дори и нещо повече... Магията на българската кухня събрана на едно място, съчетаваща вкусове от всички райони в България.
                                <br /><br />Работим предимно с домашни продукти, а десертите ни се приготвят лично от собственичката с много любов.
                                <br /><br />Доверете се на нашия дългогодишен опит и заповядайте!
                                <br /><br />Очакваме ви!


                            </p>

                            <Link to={'/'} className="btn btn-primary mt-3">Направи резервация</Link>
                        </Col>

                        <Col lg={6} className="d-none d-lg-flex justify-content-end">
                            <Image src={imageSrc} fluid alt="Нашата история" className="index-image" />
                        </Col>
                    </Row>
                </Container>

            </section>
        </>
    )
}

const query = graphql`
    query AboutHero {
        image: file(relativePath: {eq: "index/menu.jpg"}) {
            childImageSharp {
                fluid(quality: 60, maxHeight: 450) {
                    src
                }
            }
        }
    }
`;
