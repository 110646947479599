import React, { useState, useEffect } from "react";
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-image-lightbox';
import { graphql, useStaticQuery } from "gatsby";
import { Col, Container, Row, Image } from "react-bootstrap";


const trimImages = (imageSet) => {
    return imageSet.map(({ node }) => {
        return {
            src: node.childImageSharp.resize.src,
            // thumbnail: node.childImageSharp.resize.src,
            width: node.childImageSharp.resize.width,
            height: node.childImageSharp.resize.height,
        }
    })
}

const extractLinks = (images) => {
    return images.map((node) => {
        return node.src;
    })
}

const GalleryComponent = () => {

    const data = useStaticQuery(query);

    const images = trimImages(data.images.edges);
    const imageLinks = extractLinks(images);

    const [isLigthboxOpen, setIsLightboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    return (
        <>
            <Container className='container mb-5'>

                {isLigthboxOpen && (
                    <>
                        <Lightbox
                            mainSrc={imageLinks[photoIndex]}
                            nextSrc={imageLinks[(photoIndex + 1) % imageLinks.length]}
                            prevSrc={imageLinks[(photoIndex + imageLinks.length - 1) % imageLinks.length]}
                            onCloseRequest={() => { setIsLightboxOpen(false) }}
                            onMovePrevRequest={() => {
                                setPhotoIndex((photoIndex + imageLinks.length - 1) % imageLinks.length);
                            }}
                            onMoveNextRequest={() => {
                                setPhotoIndex((photoIndex + 1) % imageLinks.length);
                            }}
                        />
                    </>
                )}

                <div className="my-5">
                    {/* <Gallery
                        photos={images}
                        direction='row'
                        onClick={(e, { photo, index }) => {
                            setPhotoIndex(index);
                            setIsLightboxOpen(true);
                        }}
                    /> */}


                    <Row>
                        {images.map((image, index) => {
                            return (
                                <Col
                                    lg={6}
                                    key={index}
                                    onClick={(_) => {
                                        setPhotoIndex(index);
                                        setIsLightboxOpen(true);
                                    }}
                                    className="col-gallery"
                                >
                                    <Image fluid src={image.src} />
                                </Col>
                            );
                        })}


                    </Row>

                </div>

            </Container>
        </>
    );
}

export default GalleryComponent;

const query = graphql`
  { 
    images: allFile(filter: {sourceInstanceName: {eq: "images-gallery"}}) {
      edges {
        node {
          absolutePath
          childImageSharp {
            resize(width: 1000, quality: 90) {
              src
              width
              height
            }
          }
        }
      }
    }
  }
`;