import React from "react"
import GalleryComponent from "../components/about/gallery"
import LeftText from "../components/about/left-text"
import Map from "../components/common/map"
import USP from "../components/index/usp"
import Layout from "../components/site-wide/layout/layout"
import constants from "../utils/constants"

const AboutPage = () => (
    <Layout
        title={constants.ABOUT.META.TITLE}
    >
        <LeftText />
        <GalleryComponent />
        <USP />

        <Map />

    </Layout>
)

export default AboutPage
